import tw from 'tailwind-styled-components';
import { NextImage } from '../../elements';
import styled from 'styled-components';

export const ContactoWrapper = tw.div`
  flex
  flex-col
  gap-4

  desktop:grid
  desktop:grid-cols-2
  desktop:gap-6
`;

export const ContactImage = tw(NextImage)`
  w-full
  h-40
  object-cover
  rounded-3xl

  desktop:h-[540px]
`;

export const MessageWrapper = tw.div`
  flex
  flex-col
  justify-between
  gap-8
  bg-gray-100
  rounded-2xl
  p-6 desktop:p-20

  desktop:rounded-3xl

  ${({ $invert }) => $invert && 'desktop:-order-1'}
`;

export const MessageTitle = tw.h3`
  text-center
  text-xl desktop:text-5xl
`;

export const MessageDescription = tw.p`
  text-base
  desktop:text-xl
  desktop:text-center
`;

export const MessageRichDescription = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;

  @media (min-width: 1228px) {
    font-size: 1.25rem;
    line-height: 1.75rem;
    text-align: center;
  }
`;
export const MessageLinkDesktop = tw.div`
  hidden
  flex-col
  items-center
  gap-8

  desktop:flex
`;

export const MessageLinkMobile = tw.div`
  block

  desktop:hidden
`;
