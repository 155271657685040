import dynamic from 'next/dynamic';
import { renderLineBreaks } from '../../../utils';
import EditorJSContent from "../../editor-js-content";
import { Section } from '../index';
import {
  ContactoWrapper,
  ContactImage,
  MessageWrapper,
  MessageTitle,
  MessageDescription,
  MessageLinkDesktop,
  MessageLinkMobile,
  MessageRichDescription
} from './styles';

const Link = dynamic(() => import('../../elements/link/link'));

export const Contact = ({
  sectionTitle,
  sectionSubtitle,
  hideLineSeparation,
  image,
  subtitle,
  description,
  descriptionRichText,
  link,
  desktopCta,
  mobileCta,
  invertImagePosition,
  displaySetting
}) => {
  return (
    <Section
      title={sectionTitle}
      subtitle={sectionSubtitle}
      hideLine={hideLineSeparation}
      displayMode={displaySetting?.displayMode}
      smallMargin={displaySetting?.smallBottomMargin}
    >
      <ContactoWrapper>
        {image && <ContactImage media={image} alt={sectionTitle} />}
        <MessageWrapper $invert={invertImagePosition}>
          {subtitle && (
            <MessageTitle>{renderLineBreaks(subtitle)}</MessageTitle>
          )}
          {descriptionRichText ? (
            <MessageRichDescription>
              <EditorJSContent content={descriptionRichText} />
            </MessageRichDescription>
          ) : (
            description && (
              <MessageDescription>
                {renderLineBreaks(description)}
              </MessageDescription>
            )
          )}
          <MessageLinkDesktop>
            {link && <Link type="text" {...link} />}
            {desktopCta && <Link type="buttonPrimary" {...desktopCta} />}
          </MessageLinkDesktop>
          <MessageLinkMobile>
            {mobileCta && <Link type="buttonCta" {...mobileCta} />}
          </MessageLinkMobile>
        </MessageWrapper>
      </ContactoWrapper>
    </Section>
  );
};

export default Contact;
